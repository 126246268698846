@import 'sizeConfig';

@import 'toast';

@import 'app';

@import 'colors';

.text-align-left {
  text-align: left;
}
.bold {
  font-weight: bold;
}
.over-flow-hidden {
  overflow: hidden;
}
.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}
.c-sidebar-brand-full {
  color: white !important;
}
.d-flex-center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.pointer {
  cursor: pointer;
}
