.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.gap-2 {
  gap: 2px;
}
.gap-4 {
  gap: 4px;
}
.gap-6 {
  gap: 6px;
}
.gap-8 {
  gap: 8px;
}
.gap-16 {
  gap: 16px;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.full-width {
  width: 100%;
}
@for $i from 0 through 100 {
  .width-#{$i} {
    width: #{$i}vw !important;
  }
}

.poiter {
  cursor: pointer;
}
