@import 'scss/colors';

.loader--container {
  justify-content: center;
  display: flex;
  // height: 70vh;
  text-align: center;
  align-items: center;

  .text {
    color: $info;
  }

  .ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;

    div {
      position: absolute;
      border: 4px solid $info;
      opacity: 1;
      border-radius: 50%;
      animation: animation--loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    div:nth-child(2) {
      animation-delay: -0.5s;
    }

    @keyframes animation--loader {
      0% {
        top: 32px;
        left: 32px;
        width: 0;
        height: 0;
        opacity: 1;
      }
      100% {
        top: 0px;
        left: 0px;
        width: 64px;
        height: 64px;
        opacity: 0;
      }
    }
  }
}
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.4) !important;
  z-index: 9999;
  .text {
    color: $info;
  }
  .ripple {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;

    div {
      position: absolute;
      border: 4px solid $info;
      opacity: 1;
      border-radius: 50%;
      animation: animation--loader 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    div:nth-child(2) {
      animation-delay: -0.5s;
    }

    @keyframes animation--loader {
      0% {
        top: 32px;
        left: 32px;
        width: 0;
        height: 0;
        opacity: 1;
      }
      100% {
        top: 0px;
        left: 0px;
        width: 64px;
        height: 64px;
        opacity: 0;
      }
    }
  }
}
